import { listBetHomeDemarkOnFilter } from "../../global/GlobalVariables";
import { allBetHome, exchangeBetHome, sportsbookBetHome, listBetHomeMinuscle, NameBetHomeRenamedInFilter
  , warningBetHome, warningBetHomeMessages, warningBetHomeIcons
} from "../variables/VariablesFilterSurebet";
import React, { useEffect, useState } from "react";
import { Tooltip } from 'react-tooltip';

const BetHome = (props) => {
  const {
    openPageIndexFilter,
    statusMode,
    betHome,
    saveFilterBetHome,
    saveFilterChanged,
    requiredBetHome,
    setRequiredBetHome,
  } = props;

  let quantityAllBetHome = allBetHome.length;
  let quantityAllBetHomeExchange = exchangeBetHome.length;
  let quantityAllBetHomeSportsbook = sportsbookBetHome.length;

  const [betHomeFrontEnd, setBetHomeFrontEnd] = useState([]);
  const [requiredBetHomeFrontEnd, setRequiredBetHomeFrontEnd] = useState([]);

  function EnabledDisablebBethome(event) {
    const { checked, name } = event.target;
    const lowerCaseName = name.toLowerCase();
  
    if (checked === false) {

      if (requiredBetHomeFrontEnd.includes(lowerCaseName) === true) {
        setBetHomeFrontEnd((betHomes) => {
          return betHomes.filter((item) => item !== lowerCaseName);
        });
      
        setRequiredBetHomeFrontEnd((betHomes) => {
          return betHomes.filter((item) => item !== lowerCaseName);
        });
      } else {
        setRequiredBetHomeFrontEnd((current) => { return current.concat(lowerCaseName) });
      }

      
    } else {
      const listBetHomeToDemark = listBetHomeDemarkOnFilter.filter((item) => item.includes(lowerCaseName));

      if (listBetHomeToDemark.length > 0) {
        setBetHomeFrontEnd((current) => {
          return current
            .filter((item) => !listBetHomeToDemark[0].includes(item))
            .concat(lowerCaseName);
        });
      }
      
      else if (["sportingbet", "betboo", "bwin"].includes(lowerCaseName)) {
        // Se for uma das três, remova as outras duas, se estiverem selecionadas
        setBetHomeFrontEnd((current) => {
          return current.filter((item) => !["sportingbet", "betboo", "bwin"].includes(item)).concat(lowerCaseName);
        });
      } else {
        // Se não for uma das três, adicione à seleção (mas verifique se não é uma das três antes)
        setBetHomeFrontEnd((current) => {
          return current.filter((item) => item !== lowerCaseName).concat(lowerCaseName);
        });
      }
    }
  }
  
  useEffect(()=>{
    setBetHomeFrontEnd(betHome);
    setRequiredBetHomeFrontEnd( requiredBetHome );
    // eslint-disable-next-line
  },[])

  function markAll(event) {
    const { checked } = event.target;
    if (checked === false) {
      setBetHomeFrontEnd([]);
    } else {
      setBetHomeFrontEnd(listBetHomeMinuscle);
    }
  }

  return (
    <>
      <div
        className={`boxHeader border-bottom p-1 py-3 d-flex justify-content-start align-items-center ${
          statusMode ? "bgDark borderColor textGray" : ""
        }`}
      >
        <div
          className="ms-3 me-2"
          style={{
            width: "min-content",
            color: "rgb(13,110,253)",
            cursor: "pointer",
          }}
          onClick={openPageIndexFilter}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="35px"
            height="35px"
            fill="currentColor"
            className="bi bi-arrow-left-square-fill svgBackPage"
            viewBox="0 0 16 16"
          >
            <path d="M16 14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12zm-4.5-6.5H5.707l2.147-2.146a.5.5 0 1 0-.708-.708l-3 3a.5.5 0 0 0 0 .708l3 3a.5.5 0 0 0 .708-.708L5.707 8.5H11.5a.5.5 0 0 0 0-1z"></path>
          </svg>
        </div>
        <div
          className={`boxOptions ${
            statusMode ? "bgDark borderColor textGray" : ""
          }`}
        >
          <p className="p-0 m-0 fw-semibold text-center fs-5">
            Casas de Apostas
          </p>
        </div>
      </div>
      <div
        id="allCheckBox"
        className=" ps-4 d-flex flex-column filter fs-6"
        style={{ height: "270px", overflow: "overlay" }}
      >
        {allBetHome &&
          allBetHome.map((value, index) => 
            {
              let nameBetHomeFilterLower = value.toLowerCase();
              let isEnableWarning = nameBetHomeFilterLower in warningBetHome ?  true : false;

              return(
          (quantityAllBetHome - (quantityAllBetHomeExchange + quantityAllBetHomeSportsbook)) === index  && quantityAllBetHomeExchange > 0?
            <React.Fragment key={index}>
                      <h4 className="mode-h4">Exchange</h4>
                        <div
                      className={`form-check d-flex align-items-center ${statusMode ? "textGray" : ""
                        }`}
                    >
                      <input
                        type="checkbox"
                        id={"casasDeApostas" + index}
                        className={`form-check-input ${requiredBetHomeFrontEnd.includes( value.toLowerCase() ) === true ? 'cus' : ''}`}
                        name={value.toLowerCase()}
                        checked={ betHomeFrontEnd.includes(value.toLowerCase() )
                        }
                        onChange={EnabledDisablebBethome}
                      />

                      {nameBetHomeFilterLower in warningBetHome ? <img  data-tooltip-content={isEnableWarning ? warningBetHomeMessages[warningBetHome[nameBetHomeFilterLower]] : undefined}
                          data-tooltip-id={isEnableWarning ? 'tooltip-surebet' : undefined} className="style-icon-alert-filter" src={ warningBetHomeIcons[warningBetHome[nameBetHomeFilterLower]]} /> : ''}

                      <label
                        title=""
                        htmlFor={"casasDeApostas" + index}
                        className="form-check-label"
                        name={value.toLowerCase()}
                      >
                        {value}
                      </label>
                    </div>
                    </React.Fragment>
              : 
              (quantityAllBetHome - quantityAllBetHomeSportsbook) === index  && quantityAllBetHomeSportsbook > 0
              ?
              <React.Fragment key={index}>
                      <h4 className="mode-h4">Sportsbook</h4>
                        <div
                      className={`form-check d-flex align-items-center ${statusMode ? "textGray" : ""
                        }`}
                    >
                      <input
                        type="checkbox"
                        id={"casasDeApostas" + index}
                        className={`form-check-input ${requiredBetHomeFrontEnd.includes( value.toLowerCase() ) === true ? 'cus' : ''}`}
                        name={value.toLowerCase()}
                        checked={ betHomeFrontEnd.includes(value.toLowerCase() )
                        }
                        onChange={EnabledDisablebBethome}
                      />
                      {nameBetHomeFilterLower in warningBetHome ? <img  data-tooltip-content={isEnableWarning ? warningBetHomeMessages[warningBetHome[nameBetHomeFilterLower]] : undefined}
                          data-tooltip-id={isEnableWarning ? 'tooltip-surebet' : undefined} className="style-icon-alert-filter" src={ warningBetHomeIcons[warningBetHome[nameBetHomeFilterLower]]} /> : ''}

                      <label
                        title=""
                        htmlFor={"casasDeApostas" + index}
                        className="form-check-label"
                        name={value.toLowerCase()}
                      >
                        {value}
                      </label>
                    </div>
                    </React.Fragment>
              :
              <div
              className={`form-check d-flex align-items-center ${statusMode ? "textGray" : ""}`}
              key={index}
            >
              <input
                type="checkbox"
                id={"casasDeApostas" + index}
                className={`form-check-input ${requiredBetHomeFrontEnd.includes( value.toLowerCase() ) === true ? 'cus' : ''}`}
                name={value.toLocaleLowerCase()}
                checked={betHomeFrontEnd.includes(value.toLocaleLowerCase())}
                onChange={EnabledDisablebBethome}
              />

              {nameBetHomeFilterLower in warningBetHome ? <img  data-tooltip-content={isEnableWarning ? warningBetHomeMessages[warningBetHome[nameBetHomeFilterLower]] : undefined}
                          data-tooltip-id={isEnableWarning ? 'tooltip-surebet' : undefined} className="style-icon-alert-filter" src={ warningBetHomeIcons[warningBetHome[nameBetHomeFilterLower]]} /> : ''}

              <label
                title=""
                htmlFor={"casasDeApostas" + index}
                className="form-check-label"
                name={value.toLowerCase()}
              >
                {value in NameBetHomeRenamedInFilter ? NameBetHomeRenamedInFilter[value] : value}
              </label>
            </div>
                      )
          })}
      </div>
      <div
        className={`d-flex py-2 align-item-center border-top ps-4 fs-5 ${
          statusMode ? "bgDark borderColor textGray" : ""
        }`}
      >

      <Tooltip id="tooltip-surebet" data-tooltip-place='top' style={{ maxWidth: '100%' }}/>
        <input
          type="checkbox"
          id="show_all_bet_home"
          className={"form-check-input"}
          checked={betHomeFrontEnd.length === quantityAllBetHome}
          onChange={markAll}
        />
        <label
          title=""
          htmlFor="show_all_bet_home"
          className="form-check-label ps-2"
        >
          Mostrar Tudo
        </label>
      </div>
      <div
        className={`d-flex justify-content-end align-item-center border-top ${
          statusMode ? "bgDark borderColor textGray" : ""
        }`}
      >
        <button
          className={`btn btn-primary mt-2 me-3 fs-5 ${statusMode ? "" : ""}`}
          onClick={() => {
            openPageIndexFilter();
            saveFilterBetHome(betHomeFrontEnd);
            saveFilterChanged(
              {actualBethomeEnable:betHomeFrontEnd,}
            );
            setRequiredBetHome(requiredBetHomeFrontEnd);
            localStorage.setItem('riquiredsBethome', JSON.stringify(requiredBetHomeFrontEnd));
          }}
        >
          Aplicar e Filtrar
        </button>
      </div>
    </>
  );
};

export default BetHome;
