import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSignOutAlt,
  faUser,
  faUserEdit,
} from "@fortawesome/free-solid-svg-icons";
import DarkModeToggle from "react-dark-mode-toggle";
import "../assets/css/HeaderWebsite.css";

import Container from "react-bootstrap/Container";
import { useNavigate } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Button, Modal, Form } from "react-bootstrap";
// Importe o módulo Axios para realizar as requisições HTTP
import axios from "axios";
import { linkWhatsapp } from "../global/GlobalVariables";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(0, 0, 0, 0.8)",
    border: "none",
    borderRadius: "10px",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
  },
};

const getThemeFromStorage = () => {
  let theme = true;
  if (localStorage.getItem("theme")) {
    theme = JSON.parse(localStorage.getItem("theme"));
  }
  return theme;
};

function HeaderWebsite(props) {
  const {
    statusMode,
    setStatusMode,
    onLogout,
    isLoggedIn,
    showLogoutButton,
    notificationVolume,
    setNotificationVolume,
    userData,
    user,
  } = props;
  const wpplogo = `${process.env.PUBLIC_URL}/svgimagens/icons8-whatsapp-96.png`;
  const instalogo = `${process.env.PUBLIC_URL}/svgimagens/icons8-instagram-96.png`;
  const youtlogo = `${process.env.PUBLIC_URL}/svgimagens/icons8-reproduzir-youtube-96.png`;
  const wpplogop = `${process.env.PUBLIC_URL}/svgimagens/icons8-whatsapp-96-preto.png`;
  const instalogop = `${process.env.PUBLIC_URL}/svgimagens/icons8-instagram-96-preto.png`;
  const youtlogop = `${process.env.PUBLIC_URL}/svgimagens/icons8-reproduzir-youtube-96-preto.png`;
  const logopreto = `${process.env.PUBLIC_URL}/LOGOPRETO.png`;
  const logobranca = `${process.env.PUBLIC_URL}/LOGOBRANCO.png`;
  const iconbranca = `${process.env.PUBLIC_URL}/ICONBRANCO.png`;
  const iconpreto = `${process.env.PUBLIC_URL}/ICONPRETO.png`;
  const somon = `${process.env.PUBLIC_URL}/images/apitosomon.png`;
  const somoff = `${process.env.PUBLIC_URL}/images/apitosomoff.png`;
  const somonp = `${process.env.PUBLIC_URL}/images/apitosomonp.png`;
  const somoffp = `${process.env.PUBLIC_URL}/images/apitosomoffp.png`;
  const [isDarkMode, setIsDarkMode] = useState(getThemeFromStorage());
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    useState(false);
  const [showPhoneConfirmation, setShowPhoneConfirmation] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState("");

  // Config Usuario
  const [show, setShow] = useState(false);
  const [showV, setShowV] = useState(false);
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [phone, setPhone] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseV = () => setShowV(false);
  const handleShowV = () => setShowV(true);

  const handleUpdatePassword = () => {
    onUpdatePassword(user.id, newPassword);
    showPasswordChangeConfirmation(); // Mostrar mensagem de confirmação de senha
    handleClose();
  };

  const handleUpdatePhone = () => {
    onUpdatePhone(user.id, phone);
    showPhoneChangeConfirmation(); // Mostrar mensagem de confirmação de telefone
    handleClose();
  };

  const handleDeleteAccount = () => {
    if (window.confirm("Tem certeza de que deseja excluir sua conta?")) {
      onDeleteAccount(user.id);
    }
  };
  const showPasswordChangeConfirmation = () => {
    setConfirmationMessage("Senha alterada com sucesso!");
    setShowPasswordConfirmation(true);
  };

  const showPhoneChangeConfirmation = () => {
    setConfirmationMessage("Telefone alterado com sucesso!");
    setShowPhoneConfirmation(true);
  };

  // Crie um novo estado para controlar o volume da notificação
  // Função para atualizar a senha do usuário
  const onUpdatePassword = (userId, newPassword) => {
    // Faça uma requisição PUT para atualizar a senha no servidor
    axios
      .put(`"https://arbitragem.bet:8081/users/${userId}/settings/password`, {
        senha: newPassword,
      })
      .then((response) => {
        if (response.status === 200) {
          // A senha foi atualizada com sucesso
          console.log("Senha atualizada com sucesso!");
          // Você pode adicionar aqui qualquer ação adicional, como mostrar uma mensagem de sucesso.
        } else {
          // Lidar com erros ou exibir mensagens de erro, se necessário
          console.error("Erro ao atualizar senha:", response.data.message);
        }
      })
      .catch((error) => {
        // Lidar com erros de rede ou exceções, se necessário
        console.error("Erro ao atualizar senha:", error);
      });
  };

  // Função para atualizar o telefone do usuário
  const onUpdatePhone = (userId, newPhone) => {
    // Faça uma requisição PUT para atualizar o telefone no servidor
    axios
      .put(`"https://arbitragem.bet:8081/users/${userId}/settings/phone`, {
        phone: newPhone,
      })
      .then((response) => {
        if (response.status === 200) {
          // O telefone foi atualizado com sucesso
          console.log("Telefone atualizado com sucesso!");
          // Você pode adicionar aqui qualquer ação adicional, como mostrar uma mensagem de sucesso.
        } else {
          // Lidar com erros ou exibir mensagens de erro, se necessário
          console.error("Erro ao atualizar telefone:", response.data.message);
        }
      })
      .catch((error) => {
        // Lidar com erros de rede ou exceções, se necessário
        console.error("Erro ao atualizar telefone:", error);
      });
  };

  // Função para excluir a conta do usuário
  const onDeleteAccount = (userId) => {
    // Faça uma requisição DELETE para excluir a conta no servidor
    axios
      .delete(`"https://arbitragem.bet:8081/users/${userId}`)
      .then((response) => {
        if (response.status === 200) {
          // A conta foi excluída com sucesso
          console.log("Conta excluída com sucesso!");
          // Você pode adicionar aqui qualquer ação adicional, como mostrar uma mensagem de sucesso.
        } else {
          // Lidar com erros ou exibir mensagens de erro, se necessário
          console.error("Erro ao excluir conta:", response.data.message);
        }
      })
      .catch((error) => {
        // Lidar com erros de rede ou exceções, se necessário
        console.error("Erro ao excluir conta:", error);
      });
  };
  const navigate = useNavigate();
  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/");
    }
  }, [isLoggedIn]);
  useEffect(() => {
    if (statusMode) {
      
      document.documentElement.className = "dark-theme";
      document.documentElement.setAttribute('data-bs-theme', 'dark-theme');
    } else {
      document.documentElement.className = "light-theme";
      document.documentElement.setAttribute('data-bs-theme', 'light-theme');
    }
    localStorage.setItem("theme", statusMode);
  }, [statusMode]);
  // NOTIFICAÇÕES APOSTAS

  // Certifique-se de substituir '#root' pelo ID do elemento raiz do seu aplicativo

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const shouldShowLogoutButton = () => {
    const hash = window.location.hash;

    // Verifica se a rota atual é exatamente "/" ou não possui hash (caso padrão)
    if (hash === "" || hash === "/") {
      return true;
    }

    // Verifica se a rota atual contém "/calculator/"
    if (hash.includes("/calculator/")) {
      return false;
    }

    // Verifica se a rota atual contém "/calculator/"
    if (hash.includes("/calculator/")) {
      return false;
    }

    // Obtenha a rota atual
    const currentPath = window.location.pathname;

    // Verifique se a rota atual é exatamente "/"
    if (currentPath === "/") {
      return true;
    }

    // Verifique se a rota atual contém "/calculator/"
    if (currentPath.includes("/calculator/")) {
      return false;
    }

    // Em todos os outros casos, mostra o botão de logout

    return true;
  };

  const [volume, setVolume] = useState(notificationVolume);
  const [isMuted, setIsMuted] = useState(false);
  // Função para ajustar o volume da notificação
  const adjustNotificationVolume = (newVolume) => {
    setNotificationVolume(newVolume);
  };
  useEffect(() => {}, [volume]);

  const handleVolumeChange = (event) => {
    const newVolume = parseFloat(event.target.value);
    setNotificationVolume(newVolume);
    localStorage.setItem("volume", JSON.stringify(newVolume));
    setVolume(newVolume);
    setIsMuted(newVolume === 0);
    setNotificationVolume(parseFloat(event.target.value));
  };
  // Use o useEffect para carregar o valor do volume salvo no localStorage quando a página for carregada
  useEffect(() => {
    const savedVolume = JSON.parse(localStorage.getItem("volume"));

    if (savedVolume !== null) {
      setNotificationVolume(savedVolume);
    }
    changeStatusModes(statusMode);
  }, []);
  const changeStatusModes = (oldStatusMode) => {
    setStatusMode(oldStatusMode);

    window.localStorage.setItem("statusMode", JSON.stringify(oldStatusMode));
    const dataToJson = JSON.stringify(oldStatusMode);
    localStorage.setItem("statusMode", dataToJson);
    document.body.style.backgroundColor = !oldStatusMode ? "" : "#0d0d0d";
  };

  const changeStatusMode = (oldStatusMode) => {
    setStatusMode(!oldStatusMode);
    const dataToJson = JSON.stringify(!oldStatusMode);
    localStorage.setItem("statusMode", dataToJson);
    document.body.style.backgroundColor = oldStatusMode ? "" : "#0d0d0d";
  };

  // NOTIFICAÇÕES APOSTAS

  const title = "ARBITRAGEM.BET";
  const msg = "Nova Oportunidade";
  const icon = `${process.env.PUBLIC_URL}/favicon.ico`;
  const song = `${process.env.PUBLIC_URL}apitodefutebol.mp3`;

  return (
    <>
      {["lg"].map((expand) => (
        <Navbar
          key={expand}
          expand={expand}
          className={`bg-body-tertiary header d-flex justify-content-between align-items-center navbar navbar-expand-lg navbar-dark ResponsivHeader ${
            statusMode ? "dark-mode" : ""
          }`}
        >
          <Container fluid>
            <Navbar.Brand href="#" style={{ margin: "0" }}>
              <NavLink to="/">
                <a
                  className={`text-decoration-none fs-5 me-3 ${
                    statusMode ? "textDark" : "text-light"
                  }`}
                >
                  <img
                    src={statusMode ? logobranca : logopreto}
                    alt="Logo"
                    style={{ height: "55px", width: "auto" }}
                    className="imgfullhd"
                  />
                </a>
              </NavLink>
            </Navbar.Brand>
            <Navbar.Toggle
              aria-controls={`offcanvasNavbar-expand-${expand}`}
              className="margin20px"
            />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  <a
                    className={`text-decoration-none fs-5 me-3 ${
                      statusMode ? "textDark" : "text-light"
                    }`}
                    href="/"
                  >
                    <img
                      src={logobranca}
                      alt="Logo"
                      style={{ height: "55px", width: "auto" }}
                      className="imgfullhd"
                    />
                  </a>
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                {/* Inicio do itens do social*/}
                {shouldShowLogoutButton() && (
                  <div className="social">
                    <a
                      href="https://www.instagram.com/arbitragem.bet/"
                      target="_blank"
                      rel="noreferrer"
                      className="social-link"
                    >
                      <img
                        src={statusMode ? instalogo : instalogop}
                        alt="Instagram"
                        className="social-icon insta"
                      />
                    </a>
                    <div className="space-icon"></div>
                    <a
                      href="https://www.youtube.com/channel/UC1cr8xyol7AsWOqRj6iK9Pw"
                      target="_blank"
                      rel="noreferrer"
                      className="social-link"
                    >
                      <img
                        src={statusMode ? youtlogo : youtlogop}
                        alt="YouTube"
                        className="social-icon youtube"
                      />
                    </a>
                    <div className="space-icon"></div>
                    <a
                      href={linkWhatsapp}
                      target="_blank"
                      rel="noreferrer"
                      className="social-link"
                    >
                      <img
                        src={statusMode ? wpplogo : wpplogop}
                        alt="WhatsApp"
                        className="social-icon"
                      />
                    </a>
                  </div>
                )}
                {/* Fim do itens do social*/}
                <div className="menu mudanca-menu">
                  {shouldShowLogoutButton() && (
                    <>
                      <div className="menu mudanca-menu">
                        <ul
                          className={`menu-links ${
                            statusMode ? "textDark" : "text-light"
                          } `}
                        >
                          <NavLink to="/gerarlink">
                            <li className="nav-link">
                              <span className="navlink">
                                <a className="linknavbar">
                                  <b
                                    className={` ${
                                      statusMode ? "text-light" : "textDark"
                                    }`}
                                  >
                                    AFILIADOS
                                  </b>
                                </a>
                              </span>
                            </li>
                          </NavLink>
                          <NavLink to="/cursos">
                            <li className="nav-link">
                              <span className="navlink">
                                <a className="linknavbar">
                                  <b
                                    className={` ${
                                      statusMode ? "text-light" : "textDark"
                                    }`}
                                  >
                                    CURSO
                                  </b>
                                </a>
                              </span>
                            </li>
                          </NavLink>

                          {userData && userData.username === "admin" && (
                            <div>
                              <NavLink to="/dashboard">
                                <li className="nav-link">
                                  <span className="navlink">
                                    <a className="linknavbar">
                                      <b
                                        className={`  ${
                                          statusMode ? "text-light" : "textDark"
                                        }`}
                                      >
                                        DASHBOARD
                                      </b>
                                    </a>
                                  </span>
                                </li>
                              </NavLink>
                            </div>
                          )}
                          <div>
                            <NavLink to="/config">
                              <li className="nav-link">
                                <span className="navlink">
                                  <a className="linknavbar">
                                    <b
                                      className={`  ${
                                        statusMode ? "text-light" : "textDark"
                                      }`}
                                    >
                                      CONTA{" "}
                                    </b>
                                  </a>
                                </span>
                              </li>
                            </NavLink>
                          </div>
                        </ul>
                      </div>
                    </>
                  )}
                </div>
                <Navbar.Collapse className="justify-content-end navbar-end" style={{ display: "ruby-text", justifyContent: "center" }}>
                  <Navbar.Text>
                    <div className="d-flex align-items-center ">
                      {shouldShowLogoutButton() && (
                        <div>
                          <img
                            onClick={handleShowV}
                            src={
                              volume > 0
                                ? statusMode
                                  ? somon
                                  : somonp
                                : statusMode
                                ? somoff
                                : somoffp
                            }
                            alt="Ícone de volume"
                            style={{ height: "35px", width: "auto" }}
                          />

                          <Modal
                            show={showV}
                            onHide={handleCloseV}
                            centered
                            style={{ display: "grid" }}
                          >
                            <Modal.Body
                              className={` modal-content-vol ${
                                statusMode ? "backblack" : ""
                              }`}
                              style={{
                                background: `  ${
                                  statusMode ? "backblack" : ""
                                }`,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <Modal.Title>Volume</Modal.Title>

                              <img
                                src={statusMode ? somon : somonp}
                                alt="Ícone de volume"
                                style={{ height: "35px", width: "auto" }}
                              />
                              <hr />
                              <hr />
                              <input
                                type="range"
                                min="0"
                                max="1"
                                step="0.01"
                                value={volume}
                                onChange={handleVolumeChange}
                                style={{
                                  transform: "rotate(270deg)",
                                  width: "100%",
                                }}
                              />
                              <hr />
                              <hr />
                              <img
                                src={statusMode ? somoff : somoffp}
                                alt="Ícone de volume"
                                style={{ height: "35px", width: "auto" }}
                              />
                            </Modal.Body>
                          </Modal>
                        </div>
                      )}
                      <div
                        className={`form-check form-switch me-2 ${
                          statusMode ? "dark-switch" : ""
                        }`}
                      >
                        <DarkModeToggle
                          className="toggler"
                          size={70}
                          speed={1.3}
                          onChange={() => changeStatusMode(statusMode)}
                          checked={statusMode}
                        />
                      </div>
                    </div>
                  </Navbar.Text>
                </Navbar.Collapse>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
}

export default HeaderWebsite;
