import IconCheck from '../../assets/svg/check.svg';
import IconStopwatch from '../../assets/svg/stopwatch.svg';
import IconQuestion from '../../assets/svg/question.svg';
import IconExclamation from '../../assets/svg/exclamation.svg';

export let exchangeBetHome = [
  "BolsaDeAposta",
  "Bet-Bra",
  "Betfair EX",        
  "FulltBet"
];
export let sportsbookBetHome = [
  "BolsaDeAposta SB",
  "Bet-Bra SB",
  "FulltBet SB"
];

  export let allBetHome = [
    "Bet365",
    "Bet7",
    "BetPix365",
    "BetWarrior",
    "Betano (BR)",
    "Betboo",
    "Betcris",
    "Betfair SB",
    "Betfast",
    "Betnacional",
    "Betsson",
    "Betsul",
    "Betway",
    "Bodog (EN)",
    "Bwin",
    "EsportesDaSorte",
    "EstrelaBet",
    "Kto",
    "LeoVegas",
    "Marjosports",
    "MrJack",
    "NoviBet",
    "Pinnacle",
    "PixBet",
    "Playpix",
    "SboBet",
    "SeuBet",
    "Sportingbet",
    "Sportsbet",
    "Stake",
    "Superbet",
    "Vaidebet",
    "Vbet (LAT)"
].concat(exchangeBetHome).concat(sportsbookBetHome);

export let quantityBetHomeToSkip = (exchangeBetHome.length) + (sportsbookBetHome.length);

export let listBetHomeMinuscle = allBetHome.map((item)=> { return item.toLowerCase() });


fetch('json/b3804663.json').then((response) => response.json())
.then((data) => {
    allBetHome = data['normal_bet_home'];
    exchangeBetHome = data['exchange'];
    sportsbookBetHome = data['sportsbook'];
    allBetHome = data['normal_bet_home'].concat(exchangeBetHome).concat(sportsbookBetHome);
    listBetHomeMinuscle = allBetHome.map((item)=> { return item.toLowerCase() });
    quantityBetHomeToSkip = (exchangeBetHome.length) + (sportsbookBetHome.length);
})
.catch((error) => console.error('Error fetching data:', error));

export const NameBetHomeRenamedInFilter = {
    "Betano (BR)": "Betano",
    "Vbet (LAT)": "Vbet",
    "Bodog (EN)": "Bodog",
}
  
  export const sportsPart1 = [
    "Artes Marciais",
    "Badminton",
    "Bandy",
    "Basquete",
    "Basquete 3x3",
    "Basquete 4x4",
    "Beisebal",
    "Boliche",
    "Cricket",
    "Dardos",
    "Floorball",
    "Futebol",
    "Futebol 3x3",
    "Futebol 4x4",
    "Futebol 5x5",
    "Futebol Americano",
    "Futebol Australiano",
    "Futebol Gaélico",
    "Futebol de Praia",
    "Futebol de Salão"
  ];
  export const sportsPart2 = [
    "Futsal",
    "Golfe",
    "Handebol",
    "Handebol de Praia",
    "Hurling",
    "Hóquei",
    "Hóquei de Ar",
    "Hóquei de Ar 2x2",
    "Hóquei em Campo",
    "Lacrosse",
    "Netbol",
    "O que Onde Quando",
    "Pólo Aquático",
    "Rugby",
    "Sinuca",
    "Tênis",
    "Tênis de Mesa",
    "Voleibol",
    "Voleibol de Praia",
    "Xadrez"
  ];
  
  export const esports = [
    "Arena of Valor",
    "Call of Duty",
    "Counter-Strike",
    "Dota",
    "E-Futebol",
    "King of Glory",
    "League of Legends",
    "NBA2K",
    "Overwatch",
    "Rainbow",
    "Rocket League",
    "StarCraft",
    "Valorant",
    "Warcraft"
  ];
  
  export const allSportsMinuscle = [
    "arena of valor",
    "artes marciais",
    "badminton",
    "bandy",
    "basquete",
    "basquete 3x3",
    "basquete 4x4",
    "beisebal",
    "boliche",
    "cricket",
    "call of duty",
    "counter-strike",
    "dardos",
    "dota",
    "e-futebol",
    "floorball",
    "futebol",
    "futebol 3x3",
    "futebol 4x4",
    "futebol 5x5",
    "futebol americano",
    "futebol australiano",
    "futebol gaélico",
    "futebol de praia",
    "futebol de salão",
    "futsal",
    "golfe",
    "handebol",
    "handebol de praia",
    "hurling",
    "hóquei",
    "hóquei de ar",
    "hóquei de ar 2x2",
    "hóquei em campo",
    "king of glory",
    "lacrosse",
    "league of legends",
    "nba2k",
    "netbol",
    "o que onde quando",
    "overwatch",
    "pólo aquático",
    "rainbow",
    "rocket league",
    "rugby",
    "sinuca",
    "starcraft",
    "tênis",
    "tênis de mesa",
    "valorant",
    "voleibol",
    "voleibol de praia",
    "warcraft",
    "xadrez"
  ];

export const listKeysToCheck = [
    "betHome",
    "sports",
    "timeEvent",
    "filterOrder",
    "minProfit",
    "maxProfit"
]

export const listValueItemAccepted = {
    "betHome": "object",
    "sports": "object",
    "timeEvent": "string",
    "filterOrder": "string",
    "minProfit": "number",
    "maxProfit": "number"
}

export const listKeyItemsToUpdateInLocalStorage = {
    "betHome": "listBehomeEnabled",
    "sports": "listSportsEnabled",
    "timeEvent": "time",
    "filterOrder": "classificationSurebet",
    "minProfit": "profitMin",
    "maxProfit": "profitMax"
}

export const warningBetHome = {
  "bet365": "green",
  "bet7": "gray",
  "betpix365": "gray",
  "betwarrior": "orange",
  "betano (br)": "green",
  "betboo": "orange",
  "betcris": "green",
  "betfair sb": "green",
  "betfast": "green",
  "betnacional": "green",
  "betsson": "orange",
  "betsul": "orange",
  "betway": "orange",
  "bodog (en)": "orange",
  "bwin": "orange",
  "esportesdasorte": "gray",
  "estrelabet": "green",
  "kto": "orange",
  "leovegas": "orange",
  "marjosports": "gray",
  "mrjack": "green",
  "novibet": "red",
  "pinnacle": "green",
  "pixbet": "gray",
  "playpix": "red",
  "sbobet": "green",
  "seubet": "red",
  "sportingbet": "orange",
  "sportsbet": "green",
  "stake": "gray",
  "superbet": "green",
  "vaidebet": "orange",
  "vbet (lat)": "green",
  "bolsadeaposta": "green",
  "bet-bra": "green",
  "betfair ex": "green",
  "fulltbet": "green",
  "bolsadeaposta sb": "green",
  "bet-bra sb": "green",
  "fulltbet sb": "green",
};


export const warningBetHomeMessages = {
'green':'Casa boa',
'gray':'Casa em teste',
'orange':'Casa boa - limita rápido',
'red':'Casa não recomendada',
}


export const warningBetHomeIcons = {
  'green': IconCheck,
  'gray': IconQuestion,
  'orange': IconStopwatch,
  'red': IconExclamation,
  }


