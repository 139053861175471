import { listSortType, listSortTypeToSelect } from "../../global/GlobalVariables";
import { updateClassifiactionSurebet } from "../../global/GlobalFunctions";

import React, { useEffect } from "react";
import { listBetHomeMinuscle, allSportsMinuscle } from "../variables/VariablesFilterSurebet";


const Main = (props) => {
  const {
    openFilterBethome,
    openFilterSports,
    statusMode,
    controllerLocalStorage,
    valueMin,
    setValueMin,
    valueMax,
    setValueMax,
    quantityBetHomeEnabled,
    quantitySportsEnabled,
    timeEvent,
    setTimeEvent,
    setSports,
    setBetHome,
    saveFilterSports,
    saveFilterBetHome,
    setentradasLigth,
    entradasLigth,
    setentradasPro,
    entradasPro,
    AparecerProf,
    AparecerVivo,
    AparecerLight,
    AparecerPro,
    filterOrder,
    setFilterOrder,
    saveFilterChanged,
    requiredBetHome,
    setRequiredBetHome,
  } = props;

  const setValue = {
    profitMin: (paramenterValueMin, keyLocalStorage, saveMinFilterInCloud=false) => {

      if (saveMinFilterInCloud === true) {
        saveFilterChanged(
          {actualMinProfit: paramenterValueMin }
        )
      }

      setValueMin(paramenterValueMin);
      controllerLocalStorage["updateLocalStorage"](
        keyLocalStorage,
        paramenterValueMin
      );
    },
    profitMax: (parameterValueMax, keyLocalStorage, saveMaxFilterInCloud=false) => {

      if(saveMaxFilterInCloud === true){
        saveFilterChanged(
          { actualMaxProfit: parameterValueMax }
        )
      }

      setValueMax(parameterValueMax);
      controllerLocalStorage["updateLocalStorage"](
        keyLocalStorage,
        parameterValueMax
      );
    },
  };

  const handleChange = (event) => {
    const { value, id } = event.target;
    setValue[id](value, id, true);
  };

  function changeTime(event) {
    const { name } = event.target;

    saveFilterChanged({
      actuaTimeEvent: name,
    });
    setTimeEvent(name);
    controllerLocalStorage["updateLocalStorage"]("time", name);
  }

  function filterReset() {
    setTimeEvent("Qualquer Horário");
    controllerLocalStorage["updateLocalStorage"]("time", "Qualquer Horário");
    setValue["profitMin"](-999, "profitMin");
    setValue["profitMax"](999, "profitMax");
    setSports(allSportsMinuscle);
    saveFilterSports(allSportsMinuscle);
    setBetHome(listBetHomeMinuscle);
    saveFilterBetHome(listBetHomeMinuscle);
    
    
    setRequiredBetHome([]);
    localStorage.setItem('riquiredsBethome', JSON.stringify([]));

    saveFilterChanged(
      {
        actualBethomeEnable:listBetHomeMinuscle, 
        actualSportsEnabled:allSportsMinuscle,
        actuaTimeEvent:"Qualquer Horário",
        actualMinProfit: -999,
        actualMaxProfit: 999,
      }
    )
  }
  function EnabledDisablebEntradas(event) {
    const { checked, name } = event.target;

    
    controllerLocalStorage["updateLocalStorage"](name, checked);
    if (name === "entradaslight") {
      setentradasLigth(checked);
    } else if (name === "entradaspro") {
      setentradasPro(checked);
    }
  }

  useEffect(() => {
    if ((filterOrder in listSortTypeToSelect) === false) {
      setFilterOrder('lucro');
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div
        className={`boxHeader p-2 border-bottom ${statusMode ? "borderColor" : ""
          }`}
      >
        <div className="boxOptions">
          <div
            className={`text-Filter p-0 m-0 fw-bold text-center ${statusMode ? "textGray" : ""
              }`}
          >
            FILTROS
          </div>
        </div>
      </div>
      <div
        id="filter"
        className="px-4 d-flex flex-column align-items-center filter fs-6"
      >
        <p
          className={` text-faixa-lucro mt-2 mb-2 ${statusMode ? "textGray" : ""
            } fs-6`}
        >
          Faixa de lucro (min/max)
        </p>
        <div className=" d-flex align-items-center mb-2">
          <input
            id="profitMin"
            style={{
              width: "100px",
              height: "45px",
              backgroundColor: statusMode ? "#0d0d0d" : "",
            }}
            type="number"
            className={`form-control fs-5 ${statusMode ? "borderColor textGray" : ""
              }`}
            placeholder="0.00"
            onChange={handleChange}
            value={valueMin}
          />
          <span className=" mx-2 fs-5">/</span>
          <input
            id="profitMax"
            style={{
              width: "100px",
              height: "45px",
              backgroundColor: statusMode ? "#0d0d0d" : "",
            }}
            type="number"
            className={`form-control fs-5 ${statusMode ? "borderColor textGray" : ""
              }`}
            placeholder="0.00"
            onChange={handleChange}
            value={valueMax}
          />
        </div>

        <div
          className="dropdown fs-5 d-flex flex-column align-items-center mt-3"
          id="filter_time"
        >
          <button
            className={`dropdown-toggle btn btn-success ${statusMode ? "" : ""
              } `}
            style={{ background: "#2a960d", color: "white" }}
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Evento Durante
          </button>
          <ul
            className={`dropdown-menu ${statusMode ? "bgDark borderColor" : ""
              }`}
          >
            <li>
              <button
                className={`dropdown-item fs-6   ${"Qualquer Horário" === timeEvent ? "active" : ""
                  } ${statusMode ? "textGray hoverGray" : ""}`}
                name="Qualquer Horário"
                onClick={changeTime}
              >
                Qualquer Horário
              </button>
            </li>
            <li>
              <button
                className={`dropdown-item fs-6  ${"6 Horas" === timeEvent ? "active" : ""
                  } ${statusMode ? "textGray hoverGray" : ""}`}
                name="6 Horas"
                onClick={changeTime}
              >
                6 Horas
              </button>
            </li>
            <li>
              <button
                className={`dropdown-item fs-6  ${"12 Horas" === timeEvent ? "active" : ""
                  } ${statusMode ? "textGray hoverGray" : ""}`}
                name="12 Horas"
                onClick={changeTime}
              >
                12 Horas
              </button>
            </li>
            <li>
              <button
                className={`dropdown-item fs-6  ${"16 Horas" === timeEvent ? "active" : ""
                  } ${statusMode ? "textGray hoverGray" : ""}`}
                name="16 Horas"
                onClick={changeTime}
              >
                16 Horas
              </button>
            </li>
            <li>
              <button
                className={`dropdown-item fs-6  ${"1 Dia" === timeEvent ? "active" : ""
                  } ${statusMode ? "textGray hoverGray" : ""}`}
                name="1 Dia"
                onClick={changeTime}
              >
                1 Dia
              </button>
            </li>
          </ul>          
        </div>
        <div className="text-center ">
            <span
              id="badge_time_event"
              className={`badge rounded-pill text-white bg-primary  ${statusMode ? "" : ""
                } `}
            >
              {timeEvent}
            </span>
          </div>

        <div className="mt-3">
          <button type="button" className="btn btn-success dropdown-toggle text-light default-green " data-bs-toggle="dropdown" aria-expanded="false">
          Ordenar por
          </button>
          <ul className="dropdown-menu custom-theme-dropdown">

            {

              listSortType.map((itemSort, indexSort) => {
                return (
                  <li className={`curso-pointer ${filterOrder === itemSort.toLowerCase() ? 'classificationActive' : ''}`} key={indexSort} onClick={() => { 
                    updateClassifiactionSurebet(itemSort, setFilterOrder, 'classificationSurebet'); 
                    saveFilterChanged({actualOrderBet:itemSort.toLocaleLowerCase(),});
                    }}>
                    <span className="dropdown-item ">
                      {itemSort}
                    </span>
                  </li>
                )
              })
            }
          </ul>
        </div>

        <div className="text-center" style={{ width: "115px" }}>
          <span
            id="badge_time_event"
            className={`badge rounded-pill text-white bg-primary  ${statusMode ? "" : ""
              }`}
          >
            {(filterOrder in listSortTypeToSelect) === true ? listSortTypeToSelect[filterOrder] : 'Lucro'}
          </span>
        </div>

        <span
          id="btn_filter_bet_home"
          className={`mt-4 fw-bolder fs-6 textDecoration ${statusMode ? "textGray" : "textGray-update2"
            }`}
          style={{ cursor: "pointer" }}
          onClick={openFilterBethome}
        >
          Casas de Apostas{" "}
          <span
            id="qt_bet_home_selected"
            className=""
            style={{
              color: "#ced2d6!important",
              borderRadius: "30%",
              padding: "3px",
              fontSize: "0.9rem",
            }}
          >
            {" "}
            <b> ({quantityBetHomeEnabled})</b>{" "}
          </span>
          {
  requiredBetHome.length >= 1 ?
    <div className="text-light text-center bg-primary rounded-5 ">
      {requiredBetHome.length} em prioridade
    </div>
    :
    ""
}
        </span>

        <span
          id="btn_filter_sports"
          className={`fw-bolder fs-6 textDecoration ${statusMode ? "textGray" : "textGray-update2"
            }`}
          style={{ cursor: "pointer" }}
          onClick={openFilterSports}
        >
          Esportes{" "}
          <span
            id="qt_sports_selected"
            className=""
            style={{
              color: "#ced2d6!important",
              borderRadius: "30%",
              padding: "3px",
              fontSize: "1rem",
            }}
          >
            {" "}
            <b>({quantitySportsEnabled}) </b>{" "}
          </span>
        </span>

        {AparecerLight && (
          <>
            <div className="checkbox-wrapper">
              <span
                id="btn_filter_sports"
                className={`fw-bolder fs-6 textDecoration ${statusMode ? "textGray" : "textGray-update2"
                  }`}
                style={{ cursor: "pointer" }}
              >
                <div className="diventradas">Entradas Light </div>
              </span>
              <input
                type="checkbox"
                id={"ligth"}
                className="form-check-input"
                name={"entradaslight"}
                checked={entradasLigth}
                onChange={EnabledDisablebEntradas}
              />
            </div>
          </>
        )}
        {AparecerPro && (
          <>
            <div className="checkbox-wrapper">
              <span
                id="btn_filter_sports"
                className={`fw-bolder fs-6 textDecoration ${statusMode ? "textGray" : "textGray-update2"
                  }`}
                style={{ cursor: "pointer" }}
              >
                <div className="diventradas">Entradas Pro </div>
              </span>
              <input
                type="checkbox"
                id={"pro"}
                className="form-check-input"
                name={"entradaspro"}
                checked={entradasPro}
                onChange={EnabledDisablebEntradas}
              />
            </div>
          </>
        )}

        {AparecerProf && (
          <>
            <div className="checkbox-wrapper">
              <span
                id="btn_filter_sports"
                className={`fw-bolder fs-6 textDecoration ${statusMode ? "textGray" : "textGray-update2"
                  }`}
                style={{ cursor: "pointer" }}
              >
                <div className="diventradas">Entradas Light </div>
              </span>
              <input
                type="checkbox"
                id={"ligth"}
                className="form-check-input"
                name={"entradaslight"}
                checked={entradasLigth}
                onChange={EnabledDisablebEntradas}
              />
            </div>

            <div className="checkbox-wrapper">
              <span
                id="btn_filter_sports"
                className={`fw-bolder fs-6 textDecoration ${statusMode ? "textGray" : "textGray-update2"
                  }`}
                style={{ cursor: "pointer" }}
              >
                <div className="diventradas">Entradas Pro </div>
              </span>
              <input
                type="checkbox"
                id={"pro"}
                className="form-check-input"
                name={"entradaspro"}
                checked={entradasPro}
                onChange={EnabledDisablebEntradas}
              />
            </div>
          </>
        )}

        <button
          type="button"
          id="filter_reset"
          className={`btn btn-primary alt_margin fs-5 mt-3  ${statusMode ? "" : ""
            }`}
          onClick={filterReset}
        >
          Resetar Filtros
        </button>
      </div>
    </>
  );
};

export default Main;
