import { useParams } from "react-router-dom";
import "../assets/css/calculator.css";
import React, { useState, useEffect, useRef } from "react";
import { Parser } from "html-to-react";
import { Tooltip } from 'react-tooltip'
import { ArrowUp, ArrowDown } from "../global/ExternalIcon";
import 'react-tooltip/dist/react-tooltip.css'
import { NameBetHomeRenamed, styleDataMarket, partInfoOddSurebetJSON } from "../global/GlobalVariables";
import { decodeChance } from "../global/GlobalFunctions";
import { changeValueAllAdditionalInfoSurebet, setStateAllAdditionalInfoSurebet, finderRealOdd, eventPressButtonUpDownIncreaseDecreaseValue } from "./functions/FunctionsCalculatorContent";
import { useMediaQuery } from 'react-responsive';
import CalculatorContentMobile from "./CalculatorContentMobile";
import InputNumberFormattBR from "./InputNumberFormattBR";
import { constant } from "lodash";



const orderloop = [
  "first_home_bet",
  "second_home_bet",
  "third_home_bet",
  "fourth_home_bet",
];


function CalculatorContentTest(props) {
  const { statusMode } = props;
  const params = useParams();
  const isMobile = useMediaQuery({ query: '(max-width: 790px)' });
  const [data, setDataCalculator] = useState({});
  const [odds, setOdds] = useState([]);
  const [profitOdds, setProfitOdds] = useState([]);
  const [qtMoneyBet, setQtMoneyBet] = useState([]);
  const [qtMoney, setqtMoney] = useState(
    JSON.parse(localStorage["qauntityMoney"])
  );

  const [CalcPay, setCalcPay] = useState(-999);
  const [quantitySurebet, setQuantitySurebet] = useState(-1);
  // eslint-disable-next-line
  const [datacalculator, setDatacalculator] = useState(
    JSON.parse(localStorage["dataCalculator"])
  );

  const [profit, setProfit] = useState(0);
  const [valueRounded, setValueRounded] = useState(
    JSON.parse(localStorage["roundedValue"])
  );
  const [statusRounded, setStatusRounded] = useState(
    JSON.parse(localStorage["roundedIsActive"])
  );
  const [infoCalcFrontEntJSON, setInfoCalcFrontEntJSON] = useState(JSON.stringify({ 'qtMoney': Number(qtMoney).toString().replace('.', ','), 'allStatusComma': {} }));
  const [allAdditionalInfoSurebet, setAllAdditionalInfoSurebet] = useState('[]');
  const [showCommission, setShowCommission] = useState(false);
  let infoCalcFrontEnd = JSON.parse(infoCalcFrontEntJSON);
  let allAdditionalInfoSurebetDecoded = JSON.parse(allAdditionalInfoSurebet);
  const [valueNumber, setValueNumber] = useState(0);
  const [listValueNumber, setListValueNumber] = useState([]);
  const [canUpdateValueFavor, setCanUpdateValueFavor] = useState( JSON.stringify({'status':false, 'value': 0}) );
  const intervalRef = useRef(null);

  const eventMouseDownIncreaseValueCalculator = (value, functionUpdateState, valueToIncrement = 1) => {

    let valueToChange = Number(value);
    if (!intervalRef.current) {

      setTimeout(() => {
        valueToChange += valueToIncrement;
        functionUpdateState(valueToChange);
      }, 1);

      intervalRef.current = setInterval(() => {
        valueToChange += valueToIncrement;
        functionUpdateState(valueToChange);
      }, 200);
    }
  };

  const eventMouseDownDecreaseValueCalculator = (value, functionUpdateState, valueToDecrement = 1) => {
    let valueToChange = Number(value);
    if (!intervalRef.current) {
      setTimeout(() => {
        valueToChange -= valueToDecrement;
        functionUpdateState(valueToChange);
      }, 1);

      intervalRef.current = setInterval(() => {
        valueToChange -= valueToDecrement;
        functionUpdateState(valueToChange);
      }, 200);
    }
  };


  const handleMouseUpOrLeave = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };






  function addictionNumber(total, num) {
    return total + num;
  }

  function surebetCalculate(
    totalMoney,
    oddBetHome,
    allProfitBethome,
    roundedState,
    roundedValue,
    stakeSelected,
    allActualAdditionalInfoSurebetJSON,
    canChangeFavorValue = true
  ) {
    let oldOddBetHome = oddBetHome;
    let qtMoneyBetHome = [0, 0];
    oddBetHome = oddBetHome.map(function (str, index_str) {
      let realOdd = finderRealOdd(index_str, oddBetHome, allActualAdditionalInfoSurebetJSON)
      return realOdd;
    });
    //finderRealOdd(indexOdd, odds, allAdditionalInfoSurebet)



    let oddsTotalAddiction = oddBetHome.reduce(addictionNumber);

    for (let i = 0; i < oddBetHome.length; i++) {
      qtMoneyBetHome[i] =
        totalMoney - (totalMoney / oddsTotalAddiction) * oddBetHome[i];
      allProfitBethome[i] = qtMoneyBetHome[i] * oddBetHome[i] - totalMoney;
    }
    let additionProfit = 0;
    for (let i = 0; i < allProfitBethome.length; i++) {
      additionProfit += allProfitBethome[0];
    }

    const newProfit =
      (additionProfit / allProfitBethome.length / totalMoney) * 100;

    if (roundedState) {
      let newQtMoney = 0;
      for (let i = 0; i < oddBetHome.length; i++) {
        if (stakeSelected !== i) {
          qtMoneyBetHome[i] = Math.round(qtMoneyBetHome[i] / roundedValue) * roundedValue;
        }
        newQtMoney += qtMoneyBetHome[i];
      }

      for (let i = 0; i < oddBetHome.length; i++) {
        allProfitBethome[i] = qtMoneyBetHome[i] * oddBetHome[i] - newQtMoney;
      }
    }

    setProfitOdds(allProfitBethome);
    let totalCash = 0;
    let numberFormated = qtMoneyBetHome.map(function (num) {
      totalCash += num;
      return num;
    });
    setQtMoneyBet(numberFormated);



    if (stakeSelected !== -999) {
      let roundedResult = Math.round(totalCash * 100) / 100;
      setqtMoney(roundedResult);
    };
    setProfit(newProfit);
    
    
    if(canChangeFavorValue){

      let allActualAdditionalInfoSurebet1 = JSON.parse(allActualAdditionalInfoSurebetJSON);

      for(let i = 0; i < allActualAdditionalInfoSurebet1.length; i++){
        let nameTypeBet = allActualAdditionalInfoSurebet1[i]['typeBet'];

        if(nameTypeBet === 'lay'){          
          let valueFavor = qtMoneyBetHome[i] / (oldOddBetHome[i] - 1);
          setValueNumber(valueFavor);
          changeListValueNumber(i, valueFavor);
          
        }
      }
    }
  }

  function changeQtMoney(
    value,
    oddBetHome,
    allProfitBethome,
    roundedState,
    roundedValue,
    actualCalcPay,
    canChangeFavorValue = true
  ) {
    //const { value } = event.target;
    const totalMoney = Number(value);
    localStorage.setItem("qauntityMoney", JSON.parse(totalMoney));

    setqtMoney(totalMoney);
    surebetCalculate(
      totalMoney,
      oddBetHome,
      allProfitBethome,
      roundedState,
      roundedValue,
      actualCalcPay,
      allAdditionalInfoSurebet,
      canChangeFavorValue
    );
  }

  function changeOdds(
    value,
    indexOdd,
    totalMoney,
    oddBetHome,
    allProfitBethome,
    roundedState,
    roundedValue,
    canChangeFavorValue=true
  ) {


    let newOddBetHomeModified = oddBetHome;
    newOddBetHomeModified[indexOdd] = value;

    setOdds((prevArray) => {
      const newOddBetHomeyy = [...prevArray];
      newOddBetHomeyy[indexOdd] = value;
      return newOddBetHomeyy;
    });

    let listOddInNumber = newOddBetHomeModified.map(function (str) {
      return Number(str);
    });
    if (CalcPay === -999) {
      surebetCalculate(
        totalMoney,
        listOddInNumber,
        allProfitBethome,
        roundedState,
        roundedValue,
        CalcPay,
        allAdditionalInfoSurebet,
      );
    } else {
      adjusterValueSurebet(CalcPay, listOddInNumber);
    }
  }

  function changeBetValue(
    value,
    indexMoney,
    totalMoney,
    oddBetHome,
    allMoneyBethome,
    canChangeFavorValue=true,
    betHomeIsLay
  ) {
    const valueConverted = Number(value);


    let newMoneyBethomeModified = allMoneyBethome.map(function (str) {
      return Number(str);
    });

    if (odds[CalcPay] !== undefined && CalcPay === indexMoney) {
      let totalCash = valueConverted;
      /*
      let allAdditionalInfoSurebetConvertedToFixOdd = JSON.parse(allAdditionalInfoSurebet);

      let percenteToNumber = Number( allAdditionalInfoSurebetConvertedToFixOdd[indexOdd]['percent'])
      let oddBase = formulaA3(oddsToAjusterValue[indexOdd], percenteToNumber);
       */
      //let allAdditionalInfoSurebetConvertedToFixOdd = JSON.parse(allAdditionalInfoSurebet);
      //let percenteToNumber = Number( allAdditionalInfoSurebetConvertedToFixOdd[CalcPay]['percent'])
      let oddBase = finderRealOdd(CalcPay, odds, allAdditionalInfoSurebet);

      for (let i = 0; i < quantitySurebet; i++) {

        if (i !== CalcPay) {
          let only_odd = finderRealOdd(i, odds, allAdditionalInfoSurebet);

          let partCash = (valueConverted / only_odd) * oddBase;

          newMoneyBethomeModified[i] = partCash;
          totalCash += partCash;

        }
      }


      let roundedResultTotalCash = Math.round(totalCash * 100) / 100;

      changeQtMoney(
        roundedResultTotalCash,
        odds,
        profitOdds,
        statusRounded,
        valueRounded,
        CalcPay,   
        canChangeFavorValue     
      );
    }
    else {


      newMoneyBethomeModified[indexMoney] = valueConverted;

      let newValueTotalMoney = 0;
      for (let index = 0; index < newMoneyBethomeModified.length; index++) {
        newValueTotalMoney = newMoneyBethomeModified[index] + newValueTotalMoney;
      }
      let roundedResultTotalCash = Math.round(newValueTotalMoney * 100) / 100;
      setqtMoney(roundedResultTotalCash);

      for (let index = 0; index < newMoneyBethomeModified.length; index++) {
        let newProfitBetHomeModified =
          newMoneyBethomeModified[index] * oddBetHome[index] - newValueTotalMoney;

        setProfitOdds((prevArray) => {
          const newProfitBetHome = [...prevArray];
          newProfitBetHome[index] = newProfitBetHomeModified;
          return newProfitBetHome;
        });
      } //setProfitOdds

      setQtMoneyBet((prevArray) => {
        const newMoneyBetHome = [...prevArray];
        newMoneyBetHome[indexMoney] = value;
        return newMoneyBetHome;
      });
      
      if(betHomeIsLay === true){
        let valueFavor = valueConverted / (odds[indexMoney] - 1);
        setValueNumber(valueFavor);
        changeListValueNumber(indexMoney, valueFavor);
      }
    }
  }
  const fetchData = async (quantityMoney, roundedState, roundedValue) => {



    const dataSurebet = datacalculator;


    let dataMainSurebet = {};
    let qtkeySurebetCalculator = Object.keys(params).length;

    try {
      dataMainSurebet = dataSurebet[params.id]["main"];

      if (qtkeySurebetCalculator === 2) {

        dataMainSurebet =
          dataSurebet[params.id]["group_events"][params.id2]["main"];
      }
    } catch {
      dataMainSurebet = undefined;

      setDataCalculator({ error: "Aposta não existe tente mais tarde" });
    }



    if (dataMainSurebet !== undefined) {
      setDataCalculator(dataMainSurebet);


      let chanceBetHome = [];
      let favorValueBet = [];
      let profitBethome = [];
      let qtMoneyInBetHome = [];
      let newAddiotionalInfoSurebet = [];
      for (let i = 0; i < orderloop.length; i++) {
        let order = orderloop[i];

        if (order in dataMainSurebet) {
          let odd
          let odds
          let partInfoOddSurebet = JSON.parse(partInfoOddSurebetJSON);

          try {
            let resultChanceDecoded = decodeChance(dataMainSurebet[order]["chance"])
            odds = resultChanceDecoded['chance'];
            partInfoOddSurebet['percent'] = resultChanceDecoded['percent']
            let dataMarket = dataMainSurebet[order]['data_market']
            if (typeof dataMarket === 'string') {
              let dataMarketFormatted = dataMarket.replaceAll(' ', '').toLowerCase();
              let hasLay = dataMarketFormatted.indexOf('lay(contra)') !== -1;
              if (hasLay) {
                partInfoOddSurebet['typeBet'] = 'lay';
              }
            }
            else if (typeof dataMarket === 'object') {
              let dataMarketFormatted = (Object.values(dataMarket)).join('').replaceAll(' ', '').toLowerCase();
              let hasLay = dataMarketFormatted.indexOf('lay(contra)') !== -1;
              if (hasLay) {
                partInfoOddSurebet['typeBet'] = 'lay';
              }
            }

            let isExchange = dataMainSurebet[order]['url_bet'].toLowerCase().indexOf('exchange') !== -1;
            

          } catch (error) {
            odds = dataMainSurebet[order]["chance"]
          }
          odd = parseFloat(odds);

          newAddiotionalInfoSurebet[i] = partInfoOddSurebet
          chanceBetHome[i] = odd;
          profitBethome[i] = 0;
          favorValueBet[i] = 0;
          qtMoneyInBetHome[i] = 0;
        }
      }



      infoCalcFrontEnd['odds'] = chanceBetHome.map((item, index) => {
        infoCalcFrontEnd['allStatusComma']['odds'][index] = true;
        return item.toString().replace('.', ',');
      });

      infoCalcFrontEnd['qtMoneyBet'] = qtMoneyInBetHome.map((item, index) => {
        infoCalcFrontEnd['allStatusComma']['qtMoneyBet'][index] = true;
        return item.toString();
      });

      infoCalcFrontEnd['commission'] = newAddiotionalInfoSurebet.map((item, index) => {
        infoCalcFrontEnd['allStatusComma']['commission'][index] = true;
        return item['percent'].toFixed(2).replace('.', ',');
      });

      let infoCalcFrontEndConvertted = JSON.stringify(infoCalcFrontEnd);
      setInfoCalcFrontEntJSON(infoCalcFrontEndConvertted);

      setListValueNumber(favorValueBet);
      setOdds(chanceBetHome.map(item => item.toFixed(3)));
      setProfitOdds(profitBethome);
      setQtMoneyBet(qtMoneyInBetHome);
      setAllAdditionalInfoSurebet(JSON.stringify(newAddiotionalInfoSurebet));

      surebetCalculate(
        quantityMoney,
        chanceBetHome,
        profitBethome,
        roundedState,
        roundedValue,
        CalcPay,
        JSON.stringify(newAddiotionalInfoSurebet),
      );
    }
    ;
  };

  function capsuleFunctionAllAdditionalInfoSurebet(paramAllAdditionalInfoSurebet) {
    let roundedResultTotalCash = getCashFixed(CalcPay, odds, paramAllAdditionalInfoSurebet);

    surebetCalculate(
      roundedResultTotalCash,
      odds,
      profitOdds,
      statusRounded,
      valueRounded,
      CalcPay,
      paramAllAdditionalInfoSurebet,
    );
  }

  useEffect(() => {
    infoCalcFrontEnd['allStatusComma']['qtMoney'] = true;
    infoCalcFrontEnd['allStatusComma']['odds'] = [];
    infoCalcFrontEnd['allStatusComma']['qtMoneyBet'] = [];
    infoCalcFrontEnd['allStatusComma']['commission'] = [];
    let infoCalcFrontEndConvertted = JSON.stringify(infoCalcFrontEnd);
    setInfoCalcFrontEntJSON(infoCalcFrontEndConvertted);

    fetchData(qtMoney, statusRounded, valueRounded);

    //surebetCalculate(qtMoney, odds, profitOdds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {

    statusMode
      ? (document.body.style.backgroundColor = "#0d0d0d")
      : (document.body.style.backgroundColor = "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let actualQuantitySurebet = 0;
    for (let index = 0; index < orderloop.length; index++) {
      let order = orderloop[index];
      if (order in data) {
        actualQuantitySurebet = index + 1;
      }

    }
    setQuantitySurebet(actualQuantitySurebet);
  }, [data]);


  useEffect(() => {
    /*
    infoCalcFrontEnd['allStatusComma']['qtMoney'] = true;
    infoCalcFrontEnd['allStatusComma']['odds'] = [];
    infoCalcFrontEnd['allStatusComma']['qtMoneyBet'] = [];
    */

    let oldQtMoney = Number(infoCalcFrontEnd['qtMoney'].replace(',', '.'));
    let actualQtMoney = Number(qtMoney)

    if (oldQtMoney !== actualQtMoney) {
      if (infoCalcFrontEnd['allStatusComma']['qtMoney']) {
        infoCalcFrontEnd['qtMoney'] = actualQtMoney.toString().replace('.', ',');

      } else {
        infoCalcFrontEnd['qtMoney'] = actualQtMoney.toString();

      }
    }
    /*for (let i = 0; i < odds.length; i++) {
      let odd = Number(odds[i]);
      let oldOdd = Number(infoCalcFrontEnd['odds'][i].replace(',', '.'));


      if (odd !== oldOdd) {

        if (infoCalcFrontEnd['allStatusComma']['odds'][i]) {
          infoCalcFrontEnd['odds'][i] = odd.toString().replace('.', ',');

        } else {
          infoCalcFrontEnd['odds'][i] = odd.toString();

        }
      }
    }*/

    for (let i = 0; i < qtMoneyBet.length; i++) {
      let actualQtMoneyBetHome = Number(qtMoneyBet[i]);
      let oldQTMoneyBet = Number(infoCalcFrontEnd['qtMoneyBet'][i].replace(',', '.'));


      if (actualQtMoneyBetHome !== oldQTMoneyBet) {

        if (infoCalcFrontEnd['allStatusComma']['qtMoneyBet'][i]) {
          infoCalcFrontEnd['qtMoneyBet'][i] = actualQtMoneyBetHome.toString().replace('.', ',');

        } else {
          infoCalcFrontEnd['qtMoneyBet'][i] = actualQtMoneyBetHome.toString();

        }

      }
    }


    let infoCalcFrontEndConvertted = JSON.stringify(infoCalcFrontEnd);

    setInfoCalcFrontEntJSON(infoCalcFrontEndConvertted);
    // eslint-disable-next-line
  }, [qtMoney, qtMoneyBet]);


  function getCashFixed(indexOdd, oddsToAjusterValue = odds, actualAllAdditionalInfoSurebetJSON = allAdditionalInfoSurebet) {

    if (oddsToAjusterValue[indexOdd] !== undefined) {
      let newMoneyBethomeModified = qtMoneyBet;
      let valueConverted = Number(qtMoneyBet[indexOdd]);
      let totalCash = valueConverted;

      //allAdditionalInfoSurebet

      let oddBase = finderRealOdd(indexOdd, odds, actualAllAdditionalInfoSurebetJSON);

      for (let i = 0; i < quantitySurebet; i++) {
        if (i !== indexOdd) {
          let only_odd = finderRealOdd(i, odds, actualAllAdditionalInfoSurebetJSON);

          let partCash = (valueConverted / only_odd) * oddBase;
          newMoneyBethomeModified[i] = partCash;
          totalCash += partCash;

        }
      }

      let roundedResultTotalCash = Math.round(totalCash * 100) / 100;
      return roundedResultTotalCash;
    }
    else {
      return qtMoney;
    }
  }


  function adjusterValueSurebet(indexOdd, oddsToAjusterValue = odds) {
    setCalcPay(indexOdd)
    let roundedResultTotalCash = getCashFixed(indexOdd, oddsToAjusterValue);

    changeQtMoney(
      roundedResultTotalCash,
      oddsToAjusterValue,
      profitOdds,
      statusRounded,
      valueRounded,
      indexOdd
    );

  }

  let allAdditionalInfoSurebetConverted = JSON.parse(allAdditionalInfoSurebet);

  function changeListValueNumber(index, value){
    let newListValueNumber = listValueNumber;
    newListValueNumber[index] = value;
    
    setListValueNumber( newListValueNumber );
  }

  
  return (
    <>


      {data.error !== undefined ? (

        <div
          className="text-center d-flex flex-column justify-content-center"
          style={{ height: "calc(100vh - 78px)", background: "#CCC" }}
        >
          <h2 className="h1 fw-bold">Apostas não encontrada</h2>
          <p className="fs-4">
            Sua aposta não está mais disponível no momento.
            <br />
            Por favor tente mais tarde.
          </p>
        </div>
      ) : Object.keys(data).length > 0 ? (
        <>
          {!isMobile
            ?
            <div
              className={`card m-3 shadow ${statusMode ? "bgDark borderColor textGray shadowDark" : ""
                }`}
              style={{ marginTop: "70px" }}
            >
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h5 className="card-title m-0 fontSizeTitleDataMarketCalculator">
                      {data["first_home_bet"]["title_event"]}
                    </h5>
                    <h6 className="mb-3 fontSizeSubTitleEventCalculator">
                      {data["first_home_bet"]["sub_title_event"]}
                    </h6>
                  </div>
                  <p
                    id="profit_game"
                    className={`${profit >= 0 ? "text-success" : "text-danger"
                      } defaultFontSizeCalculator`}
                    style={{ color: "black" }}
                  >
                    {profit >= 0 ? "+" : ""}
                    {profit.toFixed(2)}%
                  </p>
                </div>

                <div className="row px-1 py-3">
                  <div className="col-9"></div>
                </div>

                <div
                  className={`text-center row border-bottom ${statusMode ? "borderColor" : ""
                    }`}
                >
                  <div className={` ${showCommission ? 'col-6' : 'col-5'} fw-bold defaultFontSizeCalculator`}>
                    Mercado
                  </div>
                  <div className="col-2 fw-bold defaultFontSizeCalculator">
                    Chance
                  </div>
                  <div className="col fw-bold defaultFontSizeCalculator d-flex justify-content-center">
                    Aposta <div className="empty-space-calculator"></div>
                  </div>
                  <div className="col fw-bold defaultFontSizeCalculator">Lucro</div>
                </div>
                {
                  // eslint-disable-next-line
                  orderloop.map((order, index) => {
                    if (order in data) {
                      let betIsLay = allAdditionalInfoSurebetDecoded[index]['typeBet'] == 'lay';
                      let paramFunction007 = function (paramState) {
                        changeListValueNumber(index, paramState);
                        let newValueStake = paramState * (odds[index] - 1);
                        changeBetValue(
                          newValueStake,
                          index,
                          qtMoney,
                          odds,
                          qtMoneyBet,
                          false,
                          betIsLay
                        );
                      };

                      let paramFunction008 = function (paramState) {
                        if(betIsLay === true){

                        }
                        changeBetValue(
                          paramState,
                          index,
                          qtMoney,
                          odds,
                          qtMoneyBet,
                          true,
                          betIsLay
                        );
                      }
                      
                      let styleMoney = {
                        minWidth: "50px",
                        width: `${(qtMoney && qtMoney.toString().length * 10) + 50
                          }px`,
                        maxWidth: `${!showCommission ? '140px' : '70px'}`,
                        padding: "8px",
                        textAlign: "right",
                        resize: "both",
                      };
                      return (
                        <>
                        <div
                          className={`row ${!betIsLay ? "border-bottom" : ""} d-flex align-items-center ${statusMode ? "borderColor" : ""
                            }`}
                          key={index}
                        >
                          <div className={` ${showCommission ? 'col-6' : 'col-5'} d-flex columnInfoNameBetHomeDataMarket align-items-center`}>
                            <div className={`${showCommission ? 'w-25' : 'w-50'}`}>
                              <div
                                className="text-decoration-none"
                                style={{ whiteSpace: "nowrap", cursor: "pointer" }}
                                onClick={() =>
                                  window.open(data[order]["url_bet_home"], "_blank")
                                }
                              >
                                <p
                                  className={`d-inline  ${showCommission ? '' : 'px-4'} m-0 text-decoration-none ${statusMode ? "textGray" : "text-black"
                                    } defaultFontSizeCalculator paddingNameBetHomeMoblie ${showCommission ? 'break-spaces-calculator' : ''}`}
                                >
                                  {data[order]["name_bet_home"].toLowerCase().replaceAll(' ', '') in
                                    NameBetHomeRenamed ? NameBetHomeRenamed[data[order]["name_bet_home"].toLowerCase().replaceAll(' ', '')] : data[order]["name_bet_home"]
                                  }
                                </p>
                              </div>
                            </div>

                            <div className={`  w-50 text-center d-flex align-items-center`}>
                              <small className="dataMarket fontSizeSmall m-0 ">
                                {

                                  data[order].hasOwnProperty('description_data_market') ?


                                    <p className={`${statusMode ? "textGray2" : ""} default-font-size m-0 `}>
                                      {
                                        styleDataMarket[data[order]['data_market']['type']]['componentFunction'](data[order]['data_market'], data[order]['description_data_market'])
                                      }

                                    </p>

                                    :
                                    <>
                                      {Parser().parse(data[order]["data_market"])}
                                    </>
                                }
                              </small>

                            </div>

                            
                                <div className={`${showCommission ? 'd-flex' : 'd-none'} align-items-center w-25`}>
                                  <input
                                    type="text"
                                    className={`odds align-self-center form-control my-1 ${statusMode ? "borderColor textGray bgDark" : ""
                                      } defaultFontSizeCalculator inputCalculator`}
                                    placeholder="0.00"
                                    style={{ width: "70px" }}
                                    value={infoCalcFrontEnd['commission'][index]}
                                    onChange={(event) => {
                                      const { value } = event.target;
                                      changeValueAllAdditionalInfoSurebet(value, allAdditionalInfoSurebetConverted, index, setAllAdditionalInfoSurebet, capsuleFunctionAllAdditionalInfoSurebet, infoCalcFrontEnd, setInfoCalcFrontEntJSON);
                                    }
                                    }

                                    onKeyDown={(event) => {
                                      //eventPressButtonUpDownIncreaseDecreaseValue(event, functionUpdateState, valueToIncrementDecrement = 1)

                                      const paramFunction = (paramValue) => {
                                        const { value } = event.target;
                                        let hasComma = value.includes(',');
                                        let valueFormattedToPut = hasComma ? paramValue.toFixed(2).replace('.', ',') : paramValue.toFixed(2);

                                        setStateAllAdditionalInfoSurebet(valueFormattedToPut, paramValue, allAdditionalInfoSurebetConverted, index, setAllAdditionalInfoSurebet, capsuleFunctionAllAdditionalInfoSurebet, infoCalcFrontEnd, setInfoCalcFrontEntJSON);
                                      }

                                      eventPressButtonUpDownIncreaseDecreaseValue(event, paramFunction, 0.01);
                                    }}
                                  />


                                  <div className="d-flex flex-column">
                                    <button className="btn p-0 m-0 btn-arrow"
                                      onMouseDown={() => {
                                        const paramFunction = (paramValue) => {
                                          const value = infoCalcFrontEnd['commission'][index];
                                          let hasComma = value.includes(',');
                                          let valueFormattedToPut = hasComma ? paramValue.toFixed(2).replace('.', ',') : paramValue.toFixed(2);

                                          setStateAllAdditionalInfoSurebet(valueFormattedToPut, paramValue, allAdditionalInfoSurebetConverted, index, setAllAdditionalInfoSurebet, capsuleFunctionAllAdditionalInfoSurebet, infoCalcFrontEnd, setInfoCalcFrontEntJSON);
                                        }
                                        eventMouseDownIncreaseValueCalculator(Number(allAdditionalInfoSurebetConverted[index]['percent']).toFixed(2), paramFunction, 0.01);
                                      }}
                                      onMouseUp={handleMouseUpOrLeave}
                                      onMouseLeave={handleMouseUpOrLeave}
                                    >
                                      <ArrowUp />
                                    </button>

                                    <button className="btn p-0 m-0 btn-arrow"
                                      onMouseDown={() => {
                                        const paramFunction = (paramValue) => {
                                          const value = infoCalcFrontEnd['commission'][index];
                                          let hasComma = value.includes(',');
                                          let valueFormattedToPut = hasComma ? paramValue.toFixed(2).replace('.', ',') : paramValue.toFixed(2);

                                          setStateAllAdditionalInfoSurebet(valueFormattedToPut, paramValue, allAdditionalInfoSurebetConverted, index, setAllAdditionalInfoSurebet, capsuleFunctionAllAdditionalInfoSurebet, infoCalcFrontEnd, setInfoCalcFrontEntJSON);
                                        }
                                        eventMouseDownDecreaseValueCalculator(Number(allAdditionalInfoSurebetConverted[index]['percent']).toFixed(2), paramFunction, 0.01);
                                      }}
                                      onMouseUp={handleMouseUpOrLeave}
                                      onMouseLeave={handleMouseUpOrLeave}
                                    >
                                      <ArrowDown />
                                    </button>
                                  </div>
                                </div>
                                
                          </div>

                          <div className="col-2 d-flex justify-content-center align-items-center fs-5">
                            <input
                              type="text"
                              className={`odds align-self-center form-control my-1 ${statusMode ? "borderColor textGray bgDark" : ""
                                } defaultFontSizeCalculator inputCalculator`}
                              placeholder="0.00"
                              style={{ width: "70px" }}
                              value={infoCalcFrontEnd['odds'][index]}


                              onChange={(event) => {
                                const { value } = event.target;

                                let valueModified = Number(value.replaceAll(',', '.'));

                                if (!isNaN(valueModified)) {

                                  infoCalcFrontEnd['odds'][index] = value;
                                  infoCalcFrontEnd['allStatusComma']['odds'][index] = value.includes(',');
                                  setInfoCalcFrontEntJSON(JSON.stringify(infoCalcFrontEnd));
                                  changeOdds(
                                    valueModified,
                                    index,
                                    qtMoney,
                                    odds,
                                    profitOdds,
                                    statusRounded,
                                    valueRounded
                                  );
                                }
                              }}
                              onKeyDown={(event) => {



                                let paramFunction = function (paramState) {
                                  infoCalcFrontEnd['odds'][index] = paramState ? paramState.toFixed(2).replace('.', ',') : paramState.toFixed(2);
                                  setInfoCalcFrontEntJSON(JSON.stringify(infoCalcFrontEnd));

                                  changeOdds(
                                    paramState,
                                    index,
                                    qtMoney,
                                    odds,
                                    profitOdds,
                                    statusRounded,
                                    valueRounded
                                  );
                                }

                                eventPressButtonUpDownIncreaseDecreaseValue(event, paramFunction, 0.01);
                              }
                              }

                            />
                            <div className="d-flex flex-column">
                              <button className="btn p-0 m-0 btn-arrow"
                                onMouseDown={() => {
                                  let paramFunction = function (paramState) {
                                    infoCalcFrontEnd['odds'][index] = paramState ? paramState.toFixed(2).replace('.', ',') : paramState.toFixed(2);
                                    setInfoCalcFrontEntJSON(JSON.stringify(infoCalcFrontEnd));


                                    changeOdds(
                                      paramState,
                                      index,
                                      qtMoney,
                                      odds,
                                      profitOdds,
                                      statusRounded,
                                      valueRounded
                                    );
                                  }
                                  eventMouseDownIncreaseValueCalculator(Number(odds[index]).toFixed(2), paramFunction, 0.01);
                                }}
                                onMouseUp={handleMouseUpOrLeave}
                                onMouseLeave={handleMouseUpOrLeave}
                              >
                                <ArrowUp />
                              </button>
                              <button className="btn p-0 m-0 btn-arrow"
                                onMouseDown={() => {
                                  let paramFunction = function (paramState) {
                                    infoCalcFrontEnd['odds'][index] = paramState ? paramState.toFixed(2).replace('.', ',') : paramState.toFixed(2);
                                    setInfoCalcFrontEntJSON(JSON.stringify(infoCalcFrontEnd));
                                    changeOdds(
                                      paramState,
                                      index,
                                      qtMoney,
                                      odds,
                                      profitOdds,
                                      statusRounded,
                                      valueRounded
                                    );
                                  }
                                  eventMouseDownDecreaseValueCalculator(Number(odds[index]).toFixed(2), paramFunction, 0.01);
                                }}
                                onMouseUp={handleMouseUpOrLeave}
                                onMouseLeave={handleMouseUpOrLeave}
                              >
                                <ArrowDown />
                              </button>
                            </div>
                          </div>

                          <div className="col d-flex justify-content-center fs-5 d-flex justify-content-center align-items-center">
                          <div>
                            <div className="d-flex align-items-center">
                            
                            <InputNumberFormattBR
                                valueNumber={qtMoneyBet[index]}
                                paramFunction007={paramFunction008}
                                classNameInput={`qt_money align-self-center form-control my-1 ${statusMode ? "borderColor textGray bgDark" : ""
                                } defaultFontSizeCalculator inputCalculator`}
                                styleMoney={styleMoney}
                              />
                            </div>
                            
                            </div>

                            

                            <div className="form-check ms-3 d-flex align-items-center">
                              <input className="form-check-input d-block" type="radio" name="flexRadioDefault" id={`checker_${order}`} onChange={() => { adjusterValueSurebet(index) }} checked={CalcPay === index ? true : false} />
                            </div>
                          </div>
                          
                          <div className="profit col d-flex justify-content-center fs-5 ">
                            <p className="m-0 defaultFontSizeCalculator"
                              data-tooltip-id={`tooltip_${order}`}
                              data-tooltip-content={profitOdds[index].toFixed(2)}
                            >



                              {profitOdds[index].toFixed(2).length > 9 ? `${profitOdds[index].toFixed(2).slice(0, 9)}...` : profitOdds[index].toFixed(2)}
                            </p>
                            <Tooltip id={`tooltip_${order}`} />
                          </div>
                        </div>
                        
                          {
                            betIsLay ?

                              <div className={`row border-bottom d-flex align-items-center ${statusMode ? "borderColor" : ""
                                }`}>

                                <div className={` ${showCommission ? "col-8":"col-7"}`}>                                  
                                </div>
                                <div className="col d-flex justify-content-center">
                                  <div className="d-flex flex-column align-items-center">
                                  <small className="style-fs-micro">Responsabilidade</small>
                                  <InputNumberFormattBR
                                    valueNumber={listValueNumber[index]}
                                    paramFunction007={paramFunction007}
                                    classNameInput={`qt_money align-self-center form-control ${statusMode ? "borderColor textGray bgDark" : ""
                                      } defaultFontSizeCalculator inputCalculator`}
                                    styleMoney={styleMoney}
                                  />
                                  <small className="style-fs-micro">Aposta a favor</small>
                                  </div>
                                  <div className="style-empty-div ms-3 "></div>
                                </div>
                                <div className="col"></div>
                              </div>
                              :
                              ""
                          }</>
                      );
                    }
                  })
                }

                <div className="row">

                  <div className={`${showCommission ? 'col-8' : 'col-7'}`}></div>

                  <div className="col">
                    <div className="d-flex justify-content-center align-items-center fs-5">
                      <input

                        id="total_value"
                        type="text"
                        style={{

                          width: `${(qtMoney && qtMoney.toString().length * 10) + 50}px`,
                          maxWidth: `${!showCommission ? '140px' : '70px'}`,
                          padding: "5px",
                          textAlign: "right",
                          minWidth: "50px",

                        }}
                        className={`qt_money align-self-center form-control my-1 ${statusMode ? "borderColor textGray bgDark" : ""
                          } defaultFontSizeCalculator inputCalculator `}
                        placeholder="R$ 0.00"
                        value={infoCalcFrontEnd['qtMoney']}
                        onChange={(event) => {

                          const { value } = event.target;

                          let valueModified = Number(value.replaceAll(',', '.'));

                          if (!isNaN(valueModified)) {
                            infoCalcFrontEnd['qtMoney'] = value;
                            infoCalcFrontEnd['allStatusComma']['qtMoney'] = value.includes(',');

                            setInfoCalcFrontEntJSON(JSON.stringify(infoCalcFrontEnd));

                            changeQtMoney(
                              valueModified,
                              odds,
                              profitOdds,
                              statusRounded,
                              valueRounded,
                              -999,
                            );
                          }

                        }}
                        onKeyDown={(event) => {



                          let paramFunction = function (paramState) {
                            changeQtMoney(
                              parseInt(paramState),
                              odds,
                              profitOdds,
                              statusRounded,
                              valueRounded,
                              -999,
                            );
                          }

                          eventPressButtonUpDownIncreaseDecreaseValue(event, paramFunction);
                        }
                        }
                      />

                      <div className="d-flex flex-column">
                        <button className="btn p-0 m-0 btn-arrow"
                          onMouseDown={() => {
                            let paramFunction = function (paramState) {
                              changeQtMoney(
                                parseInt(paramState),
                                odds,
                                profitOdds,
                                statusRounded,
                                valueRounded,
                                -999,
                              );
                            }
                            eventMouseDownIncreaseValueCalculator(qtMoney, paramFunction);
                          }}
                          onMouseUp={handleMouseUpOrLeave}
                          onMouseLeave={handleMouseUpOrLeave}
                        >
                          <ArrowUp />
                        </button>
                        <button className="btn p-0 m-0 btn-arrow"
                          onMouseDown={() => {
                            let paramFunction = function (paramState) {
                              changeQtMoney(
                                parseInt(paramState),
                                odds,
                                profitOdds,
                                statusRounded,
                                valueRounded,
                                -999,
                              );
                            }
                            eventMouseDownDecreaseValueCalculator(qtMoney, paramFunction);
                          }}
                          onMouseUp={handleMouseUpOrLeave}
                          onMouseLeave={handleMouseUpOrLeave}
                        >
                          <ArrowDown />
                        </button>
                      </div>
                      <div className="form-check ms-3 d-flex align-items-center">
                        <input className="form-check-input d-block" type="radio" name="flexRadioDefault" id="checker_total" checked={CalcPay === -999 ? true : false} onChange={() => { setCalcPay(-999) }} />
                      </div>
                    </div>
                  </div>
                  <div className="col">


                  </div>
                </div>

                <div
                  className={`card shadow-sm p-2 ${statusMode ? "borderColor textGray " : "bg_gray"
                    }`}
                  style={{ backgroundColor: statusMode ? "rgb(25, 28, 31)" : "" }}
                >
                  <div
                    className="form-check d-flex justify-content-start align-items-center"
                    style={{ fontSize: "small" }} allAdditionalInfoSurebetDecoded
                  >
                    <input
                      className="form-check-input me-1 defaultFontSizeCalculator"
                      type="checkbox"
                      id="flexCheckDefault"
                      style={{
                        minHeight: "15px",
                        minWidth: "15px",
                        fontSize: "0.85vw",
                      }}
                      checked={statusRounded}
                      onChange={(event) => {
                        const { value } = event.target;

                        // Verifica se value é uma string e não está vazio
                        if (typeof value === "string" && value.trim() !== "") {
                          setStatusRounded(!statusRounded);
                          surebetCalculate(
                            getCashFixed(CalcPay),
                            odds,
                            profitOdds,
                            !statusRounded,
                            valueRounded,
                            CalcPay,
                            allAdditionalInfoSurebet,
                          );
                          localStorage.setItem(
                            "roundedIsActive",
                            JSON.stringify(!statusRounded)
                          );
                        }
                      }}
                    />
                    <label
                      className="form-check-label me-2 fontSizeSmall"
                      htmlFor="flexCheckDefault"
                    >
                      Arredondar aposta até:
                    </label>
                    <div className="input-group" style={{ width: "120px" }}>
                      <input
                        className={`form-control h-25 ${statusMode ? "borderColor textGray bgDark" : ""
                          } inputCalculator`}
                        type="text"
                        placeholder="0.00"
                        value={valueRounded}

                        onChange={(event) => {
                          const { value } = event.target;



                          let newValueModified = Number(value.replace(',', '.'));



                          // Verifica se value é uma string e não está vazio
                          if (!isNaN(newValueModified)) {

                            setValueRounded(value);
                            surebetCalculate(
                              getCashFixed(CalcPay),
                              odds,
                              profitOdds,
                              statusRounded,
                              newValueModified,
                              CalcPay,
                              allAdditionalInfoSurebet,
                            );

                            localStorage.setItem(
                              "roundedValue",
                              JSON.stringify(newValueModified)
                            );
                          }
                        }}
                        disabled={!statusRounded}
                      />
                    </div>
                   
                        <div class="form-check px-2 d-flex align-items-center " style={{ width: '200px' }}>
                          <input class="form-check-input position-relative m-0" type="checkbox" checked={showCommission} onChange={() => setShowCommission(!showCommission)} id="commission-active" style={{ minHeight: '15px', minWidth: '15px' }} />
                          <label class="form-check-label p-0 ms-2 text-color-dark-mode" for="commission-active">
                            Mostrar comissões
                          </label>
                        </div>
                        
                  </div>

                </div>


              </div>


            </div>
            :
            <CalculatorContentMobile
              statusMode={statusMode}
              data={data}
              profit={profit}
              infoCalcFrontEnd={infoCalcFrontEnd}
              setInfoCalcFrontEntJSON={setInfoCalcFrontEntJSON}
              changeOdds={changeOdds}
              qtMoney={qtMoney}
              odds={odds}
              profitOdds={profitOdds}
              statusRounded={statusRounded}
              valueRounded={valueRounded}
              eventMouseDownDecreaseValueCalculator={eventMouseDownDecreaseValueCalculator}
              handleMouseUpOrLeave={handleMouseUpOrLeave}
              eventMouseDownIncreaseValueCalculator={eventMouseDownIncreaseValueCalculator}
              
              allAdditionalInfoSurebetConverted={allAdditionalInfoSurebetConverted}
              setAllAdditionalInfoSurebet={setAllAdditionalInfoSurebet}
              capsuleFunctionAllAdditionalInfoSurebet={capsuleFunctionAllAdditionalInfoSurebet}
              changeBetValue={changeBetValue}
              qtMoneyBet={qtMoneyBet}
              adjusterValueSurebet={adjusterValueSurebet}
              CalcPay={CalcPay}
              setCalcPay={setCalcPay}
              changeQtMoney={changeQtMoney}
              setStatusRounded={setStatusRounded}
              surebetCalculate={surebetCalculate}
              getCashFixed={getCashFixed}
              allAdditionalInfoSurebet={allAdditionalInfoSurebet}
              setValueRounded={setValueRounded}
              setShowCommission={setShowCommission}
              showCommission={showCommission}
              valueNumber={valueNumber}
              setValueNumber={setValueNumber}
              canUpdateValueFavor={canUpdateValueFavor}
              allAdditionalInfoSurebetDecoded={allAdditionalInfoSurebetDecoded}
              changeListValueNumber={changeListValueNumber}
              listValueNumber={listValueNumber}
            />
          }
        </>
      ) : (
        <section className="talign-center">
          <strong className="text-light">
            {" "}
            <h2>ARBITRAGEM.BET</h2>{" "}
          </strong>
          <span className="loader"></span>
          <p className="text-light fs-3">carregando...</p>
        </section>
      )}
    </>
  );
}

export default CalculatorContentTest;
