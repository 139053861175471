import { useState, useEffect, useRef } from "react";
import { ArrowUp, ArrowDown } from "../global/ExternalIcon";
const exceptionsMine = ['.', ',']

export default function InputNumberFormattBR(props) {
  const {    
    valueNumber,   
    paramFunction007,
    classNameInput,
    styleMoney,
  } = props;

  const [valueInputNumberDynamic, setValueInputNumberDynamic] = useState('');
  const [commaActive, setCommaActive] = useState(true);
  const incrementIntervalRef = useRef(null);
  const oldChange = useRef(0);

  const updateValueFormatted = (value) => {
    let valueModified = commaActive ? value.toFixed(2).toString().replace('.', ',') : value.toFixed(2).toString();

    setValueInputNumberDynamic(valueModified);
    
  }
  
  //console.log(25, '_________________valueNumber', valueNumber)
  const incrementValue = (valueBase) => {
    //console.log('_________________valueNumber', valueNumber)
    let newValueNumber = parseInt( (valueBase + 1) );
    //console.log(29, '-----------------newValueNumber', newValueNumber)
    oldChange.current = newValueNumber;
    paramFunction007(newValueNumber);

    updateValueFormatted(newValueNumber);
  }

  const decrementValue = (valueBase) => {
    let newValueNumber = parseInt(valueBase - 1);
    oldChange.current = newValueNumber;
    paramFunction007(newValueNumber);
    
    updateValueFormatted(newValueNumber);
  }


  const handleIncrement = () => {
    let newValueNumber = parseInt(valueNumber + 1);
    updateValueFormatted(newValueNumber);
    newValueNumber += 1;
  

    if (!incrementIntervalRef.current) {
      incrementIntervalRef.current = setInterval(() => {
        oldChange.current = newValueNumber;

        

        paramFunction007(newValueNumber);

        updateValueFormatted(newValueNumber);
        newValueNumber += 1;
      }, 200);
    }

  };


  const handleDecrement = () => {
    let newValueNumber = parseInt(valueNumber + 1);

    
    newValueNumber -= 1;
    updateValueFormatted(newValueNumber);
    newValueNumber -= 1;
  


    if (!incrementIntervalRef.current) {
      incrementIntervalRef.current = setInterval(() => {
        oldChange.current = newValueNumber;
        paramFunction007(newValueNumber);

        updateValueFormatted(newValueNumber);
        newValueNumber -= 1;
      }, 200);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'ArrowUp') {
      let value = event.target.value;
      let valueModified = value.replace(',', '.');
      let valueFiltered = Number(valueModified);


      if (isNaN(valueFiltered) === false) {
        incrementValue(valueFiltered);
      }
    } else if (event.key === 'ArrowDown') {
      let value = event.target.value;
    let valueModified = value.replace(',', '.');
    let valueFiltered = Number(valueModified);


    if (isNaN(valueFiltered) === false) {
      decrementValue(valueFiltered);
    }
    }
  };

  const checkHasComma = (value) => {
    if (value.includes(',') === true || value.includes('.') === true) {
      setCommaActive(value.includes(','));
    }
  }


  const handleChange = (e) => {

    let value = e.target.value;
    let valueModified = value.replace(',', '.');
    let valueFiltered = Number(valueModified);


    if (isNaN(valueFiltered) === false) {
      setValueInputNumberDynamic(value);
      checkHasComma(value);
      oldChange.current = valueFiltered;
      paramFunction007(valueFiltered);

      
    }
    else if (exceptionsMine.includes(value) === true) {
      setValueInputNumberDynamic(value);
      checkHasComma(value);
      //oldChange.current = 0;
      //paramFunction007(0);      
    }


  };

  const stopCount = () => {
    clearInterval(incrementIntervalRef.current);
    incrementIntervalRef.current = null;
  };


  useEffect(() => {
    if (oldChange.current !== valueNumber) {
      oldChange.current = valueNumber;     
      //console.log(123, '¨¨¨¨¨¨¨¨¨¨¨¨¨¨¨¨¨¨¨¨¨¨¨¨¨¨¨¨¨¨¨¨¨¨¨¨¨¨¨¨¨¨', valueNumber);
      updateValueFormatted(valueNumber);
    }
  }, [valueNumber]);

  //console.log('valueNumber', valueNumber);
  return (
    <>
    
      <div className="d-flex align-items-center">
        <input className={classNameInput} type="text" value={valueInputNumberDynamic} onChange={handleChange} onKeyDown={(event)=>{handleKeyDown(event);}}
          style={styleMoney}
        />
        <div className="d-flex flex-column align-items-center">
          <button className="btn p-0 m-0 btn-arrow"
            onMouseDown={handleIncrement}
            onMouseUp={stopCount}
            onMouseLeave={stopCount}
          >
            <ArrowUp />
          </button>
          <button className="btn p-0 m-0 btn-arrow"
            onMouseDown={handleDecrement}
            onMouseUp={stopCount}
            onMouseLeave={stopCount}
          >
            <ArrowDown />
          </button>
        </div>

      </div>
    </>
  );
}
